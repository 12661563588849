import React from "react";

import { Box, CardContent, Stack, SxProps, Theme } from "@mui/material";

import {
  cancelLogin,
  checkIfBankIdLoginStarted,
  startBankIdLogin
} from "shared/bankidApi";
import { isMobileBrowser } from "shared/utils";
import BankIdMobileLoginQr from "./BankIdMobileLoginQr";
import BankIdThisDeviceLogin from "./BankIdThisDeviceLogin";

interface BankIdFrameProps {
  sx?: SxProps<Theme>;
}

const getLoginMethodFromLoginHint = (login_hint: string) => {
  if (login_hint === "" && isMobileBrowser()) {
    return "this_device";
  }
  return login_hint === "this_device" ? "this_device" : "mobile"

};

const BankIdFrame: React.FC<BankIdFrameProps> = ({ sx = [] }) => {
  const searchParams = new URLSearchParams(window.location.search);
  const login_challenge = searchParams.get("login_challenge") as string;
  const login_hint = searchParams.get("login_hint") ?? "";
  const sign = searchParams.get("sign") ?? "";
  const [loginMethod, setLoginMethod] = React.useState(
    getLoginMethodFromLoginHint(login_hint)
  );
  const [loginFailed, setLoginFailed] = React.useState(false);
  const [bankIdOpened, setBankIdOpened] = React.useState(false);
  const [qrCode, setQrCode] = React.useState<string | null>(null);
  const bankIdUrl = React.useRef("");
  const [userMessage, setUserMessage] = React.useState("");
  const pollingCallback = React.useRef<NodeJS.Timeout | null>(null);
  // const [bankIdStatusResult, setBankIdStatusResult]=React.useState(loginFailed?null:bankIdStatus(login_challenge, loginMethod))
  const [loginStatus, setLoginStatus] = React.useState<any>("");
  const [hintCode, setHintcode] = React.useState<any>("");
  

  React.useEffect(() => {
    if (loginMethod === "mobile") {
      startBankIdLogin(
        login_challenge,
        pollingCallback,
        loginMethod,
        bankIdUrl,
        setBankIdOpened,
        setLoginFailed,
        setUserMessage,
        setQrCode
      );
    } else if (loginMethod === "this_device") {
      checkIfBankIdLoginStarted(
        login_challenge,
        pollingCallback,
        loginMethod,
        setBankIdOpened,
        setLoginFailed,
        setUserMessage
      );
    }
    return () => {
      if (pollingCallback.current) {
        clearTimeout(pollingCallback.current);
        pollingCallback.current = null;
      }
    };
  }, [login_challenge, loginMethod]);

  const onBackClick = async (_event: React.MouseEvent<HTMLButtonElement>) => {
    if (login_challenge !== null) {
      try {
        await cancelLogin(login_challenge);
      } catch {
        // no meaningful error handling here, we just go back which should reset
        // everything
      }
    }
    setBankIdOpened(false);
    history.back();
  };

  const renderedLoginMethod = () => {
    switch (loginMethod) {
      case "this_device":
        return (
          <BankIdThisDeviceLogin
            bankIdOpened={bankIdOpened}
            setBankIdOpened={setBankIdOpened}
            onBackClick={onBackClick}
            loginFailed={loginFailed}
            startBankIdLogin={async (personalNumber?: string) => {
              await startBankIdLogin(
                login_challenge,
                pollingCallback,
                loginMethod,
                bankIdUrl,
                setBankIdOpened,
                setLoginFailed,
                setUserMessage,
                setQrCode,
                personalNumber
              );
            }}
            bankIdUrl={bankIdUrl}
            userMessage={userMessage}
            loginStatus={loginStatus}
            setLoginMethod={setLoginMethod}
            sign={sign}
          />
        );
      case "mobile":
        return (
          <BankIdMobileLoginQr
            onBackClick={onBackClick}
            loginFailed={loginFailed}
            qrCode={qrCode}
            userMessage={userMessage}
            loginStatus={loginStatus}
            hintCode={hintCode}
            setLoginMethod={setLoginMethod}
            sign={sign}
          />
        );
    }
  };

  return (
    //mt på boxen var 130px innan varningsrutan kom på plats
    <Box
      sx={{
        pl: "15px",
        pr: "15px",
        border: "none",
        m: "0 auto 0 auto",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <CardContent>
        <Stack spacing={2}>{renderedLoginMethod()}</Stack>
      </CardContent>
    </Box>
  );
};

export default BankIdFrame;
