import { Box, Button, CircularProgress, Typography } from "@mui/material";
import QRCode from "qrcode";
import React from "react";

import * as CustomMessages from "./CustomMessages.json";
import BottomCornerBorderQRCode from "./images/BottomCornerBorderQRCode";
import CheckMarkIcon from "./images/CheckMarkIcon";
import InfoIcon from "./images/InfoIcon";
import TopCornerBorderQRCode from "./images/TopCornerBorderQRCode";

interface BankIdMobileLoginQrProps {
  onBackClick: (event: React.MouseEvent<HTMLButtonElement>) => Promise<void>;
  loginFailed: boolean;
  qrCode: string | null;
  userMessage: string;
  loginStatus:string;
  hintCode:string;
  setLoginMethod:(loginMethod: string) => void;
  sign:string;
}

const BankIdMobileLogin: React.FC<BankIdMobileLoginQrProps> = ({
  onBackClick,
  loginFailed,
  qrCode,
  userMessage,
  loginStatus,
  hintCode,
  setLoginMethod,
  sign
}) => {
  const qrCodeCanvasRef = React.useRef(null);
  const cancelBtnText=sign=="true"?"Avbryt signering":"Avbryt inloggning";
  const headingText=sign=="true"?"Signera med BankID":"Identifiera dig med BankID";
  const sucessText=sign=="true"?"Signeringen lyckades":"Identifieringen lyckades";

  React.useEffect(() => {
    getMsg();
    if (qrCode === null || qrCodeCanvasRef?.current === null) {
      return;
    }
    QRCode.toCanvas(qrCodeCanvasRef.current, qrCode, {
      width: 212,
    });
  }, [qrCode, qrCodeCanvasRef, loginFailed]);

  const [displayMessage, setDisplayMessage] = React.useState("");
  const [displayMessageHeading, setDisplayMessageHeading] = React.useState("");
  const searchParams = new URLSearchParams(window.location.search);
  const login_challenge = searchParams.get("login_challenge") as string;

  const getMsg=()=>{
    let msg:string="";
    switch (userMessage) {
      case CustomMessages.startFailed.Default:
        setDisplayMessage(CustomMessages.startFailed.Custom)
        setDisplayMessageHeading(CustomMessages.startFailed.Heading)
          break;

      case CustomMessages.certificateErr.Default:
        setDisplayMessage(CustomMessages.certificateErr.Custom)
        setDisplayMessageHeading(CustomMessages.certificateErr.Heading)
          break;

      case CustomMessages.expiredTransaction.Default:
        setDisplayMessage(CustomMessages.expiredTransaction.Custom)
        setDisplayMessageHeading(CustomMessages.expiredTransaction.Heading)
          break;

      case CustomMessages.userCancel.Default:
        setDisplayMessage(CustomMessages.userCancel.Custom)
        setDisplayMessageHeading(CustomMessages.userCancel.Heading)
          break;
    
      default:
        setDisplayMessage(userMessage)
        setDisplayMessageHeading("Något gick fel")
        break;
      }  
  }


  return (
  <>
   {hintCode==="userSign" &&
   <>
    <Typography variant="h1" sx={{
      fontSize:"48px",
      mb:"50px",
      color:"#1D252C",
      fontWeight:500,
      "@media (max-width: 625px)":{
        fontSize:"32px",
        mb:"10px",
      }}}>
      Skriv in din säkerhetskod i BankID-appen
    </Typography>
    <CircularProgress sx={{m:"50px auto 65px auto !important"}}/>
    <Button
          variant="outlined"
          fullWidth={false}
          disableRipple={true} disableTouchRipple={true}
          sx={{ 
            m:"0 auto !important",
            padding:"8px",
            width:"246px",
            textTransform:"uppercase",
            border:"2px solid #031E40",
            borderRadius:"100px",
            color:"#1D3442",
            fontSize:"16px",
            height:"50px",
            "&:hover":{
              backgroundColor:"#878D9726",
              border:"2px solid #031E40"
            },
            "&:focus-visible":{
              outline: "2px #1D252C solid", /*middle line*/
              outlineOffset: "-6px",/*middle line*/
              boxShadow: "0 0 0 6px #BBCBD750", /*outer line*/
              border:"2px solid #1D252C" /* inner line     */  
            },     
          }}
          onClick={onBackClick}
          >
          {cancelBtnText}
    </Button>
    </>
  }
      {loginFailed && loginStatus!=="complete" ? (
        <>
        <Typography variant="h1" sx={{
          fontSize:"48px",
          mb:"50px",
          fontWeight:500,
          lineHeight:"52px",
          color:"#1D252C",
          maxWidth:"80vw",
          "@media (max-width: 625px)":{
            fontSize:"32px",
            mb:"10px",
            lineHeight:"36px",
          }}}>
          {headingText}
        </Typography>
        <Box sx={{
          width:"550px",
          maxWidth:"80vw",
          boxSizing:"border-box",
          padding:"20px",
          backgroundColor:"#FFEDEB",
          textAlign:"center",
          m:"0 auto",
          "@media (max-width: 625px)":{
            width:"345px"
          }
          }}>
          <InfoIcon sx={{width:"26px"}}/>
            <Typography variant="h3" sx={{
              fontSize:"24px",
              lineHeight:"32px",
              color:"#1D252C",
              fontWeight:500,
              "@media (max-width: 625px)":{
                fontSize:"21px",
                lineHeight:"30px",
              }
              }}>{displayMessageHeading}</Typography>
            <Typography sx={{
              fontSize:"18px",
              lineHeight:"28px",
              color:"#1D252C",
              "@media (max-width: 625px)":{
                fontSize:"16px",
                lineHeight:"24px",
              }
              }}>{displayMessage}</Typography>
          <Button disableRipple={true} sx={{
            height:"50px",
            backgroundColor:"#1D3442",
            borderRadius:"100px",
            textTransform:"uppercase",
            color:"white",
            p:"8px",
            width:"183px",
            m:"25px auto 20px auto",
            fontWeight:600,
            letterSpacing:0.7,
            "&:hover":{
              backgroundColor:"#2C4E67"
            },
            "&:focus-visible":{
              outline: "2px #1D252C solid", //middle line
              outlineOffset: 0,/*middle line*/
              boxShadow: "0 0 0 6px #BBCBD750", //outer line
              border:"2px solid white"  /*inner line*/
            },       
            }}
            onClick={()=>history.back()}
            >Försök igen</Button>
        </Box>
        </>
      ) : (
        <>
        {hintCode!=="userSign" && loginStatus!=="complete" &&
        <>
         <Typography variant="h1" sx={{
          fontSize:"48px",
          mb:"15px",
          fontWeight:500,
          lineHeight:"56px",
          color:"#1D252C",
          "@media (max-width: 625px)":{
            fontSize:"32px",
            mb:"10px",
            lineHeight:"36px",
          }}}>
          {headingText}
        </Typography>
        <Typography sx={{
        width:"565px",
        maxWidth:"80vw",
        fontSize:"18px",
        margin:"0 auto !important",
        color:"#1D252C",
        "@media (max-width: 625px)":{
          fontSize:"16px",
          //mb:"30px",
          width:"335px",
          lineHeight:"24px"
        }}}>
        Öppna BankID-appen i din mobiltelefon och tryck på Skanna QR-kod. Rikta kameran mot QR-koden som visas här nedanför.
        </Typography>
          <Box sx={{
            width:"208px", 
            color:"#1D252C",
            m:"50px auto 60px auto !important",
            "@media (max-width: 625px)":{
              width:"158px"
            }}}>
          <TopCornerBorderQRCode sx={{
            zIndex:6,
            width:"100%",
            justifyContent:"space-between",
            display:"flex"
            }}/>
            <Box
              component="canvas"
              id="qrcode"
              sx={{
                height:"200px !important",
                width:"200px !important",
                mt:"-28px",
                ml: "auto !important", // for some reason, the Stack element's margin will overwrite these
                mr: "auto !important",
                display: "block",
                zIndex: 5,
                "@media (max-width: 625px)":{
                  width:"150px !important",
                  height:"150px !important",
                }
              }}
              ref={qrCodeCanvasRef}
              aria-label={"QR-kod för inloggning med BankID"}
            />
            <BottomCornerBorderQRCode sx={{zIndex:6, width:"100%", justifyContent:"space-between", display:"flex", mt:"-28px"}}/>
        </Box>
        <Button onClick={()=>setLoginMethod("this_device")} disableRipple={true} disableTouchRipple={true}
          sx={{
          display:{xs:"none", md:"flex"},
          textTransform:"none",
          fontSize:"18px",
          textDecoration:"underline",
          width:"fit-content",
          margin:"0 auto !important",
          color:"#1D252C",
          fontWeight:"400",
          height:"28px",
          "&:hover":{
            color:"#677079",
            textDecoration:"underline",
            background:"rgba(0,0,0,0)"
          },
          "&:visited":{
            color:"#677079",
            textDecoration:"underline",
            background:"rgba(0,0,0,0)"
          },
          "&:focus-visible":{
            borderRadius:0,
            outline: "2px #1D252C solid", /*middle line*/
            outlineOffset: 0,/*middle line*/
            boxShadow: "0 0 0 6px #BBCBD750 "/*outer line*/
          },
          }}>Öppna BankID på den här enheten istället</Button>
        <Button onClick={()=>setLoginMethod("this_device")} disableRipple={true} disableTouchRipple={true}
          sx={{
          display:{xs:"flex", md:"none"},
          textTransform:"none",
          fontSize:"18px",
          textDecoration:"underline",
          width:"fit-content",
          margin:"0 auto !important",
          color:"#1D252C",
          fontWeight:"400",
          height:"28px",
          "&:hover":{
            color:"#677079",
            textDecoration:"underline",
            background:"rgba(0,0,0,0)"
          },
          "&:visited":{
            color:"#677079",
            textDecoration:"underline",
            background:"rgba(0,0,0,0)"
          },
          "&:focus-visible":{
            outline: "2px #1D252C solid", /*middle line*/
            outlineOffset: 0,/*middle line*/
            boxShadow: "0 0 0 6px #BBCBD750 ",/*outer line*/
            borderRadius:0
          },
          "@media (max-width: 625px)":{
            fontSize:"16px"
          }
          }}> Använd BankID på den här enheten</Button>
        </>
        }
        </>
          )
        }
        {loginStatus==="complete"&&
        <>
          <Typography variant="h1" sx={{
          fontSize:"48px",
          mb:"15px",
          fontWeight:500,
          lineHeight:"52px",
          color:"#1D252C",
          "@media (max-width: 625px)":{
            fontSize:"32px",
            mb:"10px",
            lineHeight:"36px",
          }}}>
          {headingText}
        </Typography>
        <Box sx={{
          width:"550px",
          m:"50px auto 0 auto !important",
          boxSizing:"border-box",
          padding:"20px",
          backgroundColor:"#EEF8E2",
          textAlign:"center",
          "@media (max-width: 625px)":{
            width:"345px"
          }
          }}>
          <CheckMarkIcon sx={{width:"26px"}}/>
          <Typography variant="h3" sx={{
            fontSize:"24px",
            lineHeight:"32px",
            color:"#1D252C",
            fontWeight:500,
            "@media (max-width: 625px)":{
              fontSize:"21px",
              lineHeight:"30px",
            }
            }}>{sucessText}</Typography>
          <Typography sx={{
            fontSize:"18px",
            lineHeight:"28px",
            color:"#1D252C",
            "@media (max-width: 625px)":{
              fontSize:"16px",
              lineHeight:"24px",
            }
            }}>Du skickas nu vidare.</Typography>
      </Box>
      </>
   }
    </>
  );
};

export default BankIdMobileLogin;
